import React from 'react';
import { withSitecoreContext, RichText, Text } from '@sitecore-jss/sitecore-jss-react';
import { Formik } from "formik";
import { ApolloConsumer } from 'react-apollo';
import { loader as gqlLoader } from 'graphql.macro';
import './ratings.css';
import './comment.css';
import StarRatingV2 from '../StarRating';
import i18next from 'i18next';
import ReactComponent from "../../ReactComponent";

const RATINGS_QUERY = gqlLoader('./RatingReviews.graphql');

class RatingReviews extends ReactComponent{

    constructor(props) {
        super(props);

        this.state = {
            data: null,
            error: '',
            show: true,
            status: "",
            rating: "",
            clickedStar: "",
            username: "",
            commentValue: ""
        }
        this.onStarClick = this.onStarClick.bind(this);
        this.userNameChange = this.userNameChange.bind(this);
        this.commentChange = this.commentChange.bind(this);
    }
    componentDidMount(){
        var productId = this.props.sitecoreContext.itemId.replace(/-/g, "").toLowerCase();
        var QUERY = 'query=query($product:String!){search(index:"'+ this.indexName +'",rootItem:"/sitecore/content/' + this.appName + '/Content/Rating and Reviews",fieldsEqual:[{name:"_templatename",value:"RatingReviewItem"},{name:"product",value:$product},{name:"ratingstatus",value:"e8b6acc0a153400f9494941bbdca46ce"}]) {results{items{item{id name...on RatingReviewItem{userName{value} rating{numberValue} ratingstatus{value targetItem{ name }} creationDate{value} comment{value} product{value} }}}}}}&variables={"product": "'+productId+'"}';
        fetch(this.getGraphqlEndpoint + QUERY)
        .then(res => res.json())
        .then(
            (result) => {
                this.setState({
                    data: result
                });
            
            },
            (error) => {
            this.setState({
                error: error
            });
            }
        )
    }
    onStarClick(event){
        var ratingValue =event.target.parentElement.getAttribute("data-rating")
        var clickedStarId = event.target.id
        this.setState({
            rating: ratingValue,
            clickedStar: clickedStarId

        })
    }

    userNameChange(event){
        this.setState({username: event.target.value});
    }
    commentChange(event){
        this.setState({commentValue: event.target.value});
    }
    
    render(){
        return(
            <>
                <div className="container margin_custom">
    <div className="row_custom_small">
        <h1 className="title-review"><Text field={this.props.fields && this.props.fields.RatingAndReviewTitle} /></h1>
        <div id="reviews" className="Reviews">
            <div id="comments">
                <h1 className="Reviews-title">Valoraciones</h1>
                <a name="viewForm" />
                {this.state.data && this.state.data == null && <p className="noreviews another__font"><Text field={this.props.fields && this.props.fields.NoRatingsMessage} /></p> }
                {this.state.data && this.state.data != null &&
                    <ol className="commentlist">
                        {this.state.data.data && this.state.data.data.search && this.state.data.data.search.results.items.map((commentItem,index)=>(
                            <li className="review even thread-even depth-1" key={index} id="li-comment-36">
                                 <div id="comment-36" className="comment_container">
                                    <div className="comment-text">
                                        <p className="meta">
                                            <strong className="review__author">{commentItem.item.userName.value} </strong>
                                            <time className="review__published-date" >{commentItem.item.creationDate.value}</time>
                                        </p>
                                        <StarRatingV2 value={commentItem.item.rating.numberValue} />
                                        <div className="description">
                                            <p>{commentItem.item.comment.value}</p>
                                        </div>
                                    </div>
                                </div>
                            </li>
                        ))}
                    </ol>
                }
            </div>
            <div id="review_form_wrapper">
                <a name="reviewForm" />
                <div id="review_form">
                    <div id="respond" className="comment-respond">
                        <span id="reply-title" className="comment-reply-title same__font">
                            <Text field={this.props.fields && this.props.fields.RatingWriteComment} />
                        </span>
                        {this.state.show === false &&  this.state.status === "success" &&
                            <div className="alert alert-success" role="alert">
                                <Text field={this.props.fields && this.props.fields.RatingThankyou} />
                            </div> 
                        }
                        {this.state.show && this.state.show === true &&
                        <ApolloConsumer>
                        {client => (
                            <Formik
                                enableReinitialize
                                initialValues={{  userName: this.state.username,  rating: this.state.rating ,comment: this.state.commentValue, product: "",privacy: false }}
                                onSubmit={async (values, { setErrors, resetForm, setSubmitting }) => {
                                const { data } = await client.query({
                                    query: RATINGS_QUERY,
                                    variables: {  userName: values.userName, rating: values.rating, comment: values.comment, product: this.props.sitecoreContext.itemId }
                                });
                                if (data.ratingreviews === "Review Submitted Successfully") {
                                    this.setState({
                                        show: false,
                                        status: "success"
                                    })
                                }
                                else {
                                    setErrors({submit: "Error sending review."});
                                    setSubmitting(false);
                                }
                                
                                }}
                                validate={values => {
                                    let errors = {};
                                    if (!values.privacy) {
                                        errors.privacy = "Required";
                                    }	
                                    if (!values.comment) {
                                        errors.comment = "Required";
                                    }

                                    	
                                    return errors;
                                }}
                            >
                                {props => {
                                const {
                                    values,
                                    touched,
                                    errors,
                                    handleChange,
                                    handleSubmit
                                } = props;	
                                return (
                                    <>
                                        <div className="pr-3">
                                            
                                            <form id="commentform" className="comment-form another__font" onSubmit={handleSubmit}>
                                                <RichText className="comment-notes" tag="p" field={this.props.fields && this.props.fields.RequiredFieldsInfo} />
                                                <div className="comment-form-rating same__font">
                                                    <label for="rating"><Text field={this.props.fields && this.props.fields.RatingLabel} /></label>
                                                    <p className="stars another__font">
                                                        <span>
                                                            <a className="star-1 same__font" data-rating="1" onClick={this.onStarClick}>
                                                                <i className={"rating-1" === this.state.clickedStar || 1 <= this.state.rating ? "fa fa-star": "far fa-star off"} id="rating-1" ></i>
                                                            </a>
                                                            <a className="star-2 same__font" data-rating="2" onClick={this.onStarClick}>
                                                                <i className={"rating-2" === this.state.clickedStar || 2 <= this.state.rating ? "fa fa-star": "far fa-star off"} id="rating-2"></i>
                                                            </a>
                                                            <a className="star-3 same__font" data-rating="3" onClick={this.onStarClick}>
                                                                <i className={"rating-3" === this.state.clickedStar || 3 <= this.state.rating ? "fa fa-star": "far fa-star off"} id="rating-3" ></i>
                                                            </a>
                                                            <a className="star-4 same__font" data-rating="4" onClick={this.onStarClick}>
                                                                <i className={"rating-4" === this.state.clickedStar || 4 <= this.state.rating ? "fa fa-star": "far fa-star off"} id="rating-4"></i>
                                                            </a>
                                                            <a className="star-5 same__font" data-rating="5" onClick={this.onStarClick}>
                                                                <i className={"rating-5" === this.state.clickedStar  ? "fa fa-star": "far fa-star off"} id="rating-5"></i>
                                                            </a>
                                                        </span>
                                                    </p>
                                                </div>
                                                <p className="comment-form-comment another__font">
                                                    <textarea id="comment" name="comment" cols="45" rows="8" placeholder={i18next.t('PlaceholderComment')} required="" value={this.state.commentValue} onChange={this.commentChange}
                                                     />
                                                    {errors.comment && touched.comment && (
                                                        <div className="alert alert-danger" role="alert">
                                                            {errors.comment}
                                                        </div>
                                                        
                                                    )}
                                                </p>
                                                <p className="form-control-wrap">

                                                </p>
                                                <div className="row_custom_small input__nombre">
                                                    <p className="comment-form-author another__font">
                                                        <input type="text" name="userName" id="userName" size="40" placeholder={i18next.t("PlaceholderFullname")} value={this.state.username} onChange={this.userNameChange} />
                                                    </p>
                                                </div>
                                                <p className="pprivacy same__font">
                                                    <input type="checkbox" id="privacy" name="privacy" value="privacy-key" checked={values.privacy === true} className="privacyBox" onChange={handleChange} />
                                                    <RichText tag="span" field={this.props.fields && this.props.fields.PrivacyCheckBoxLabel} />
                                                    {errors.privacy && touched.privacy && (
                                                        <div className="alert alert-danger" role="alert">{errors.privacy}</div>
                                                    )}
                                                </p>
                                                <p>
                                                </p>
                                                <p className="form-submit another__font">
                                                    <input name="submit" type="submit" id="submit" className="btn btn_custom_o another__font" value="Submit" />
                                                    <input type="hidden" name="comment_post_ID" value="983" id="comment_post_ID" />
                                                    <input type="hidden" name="comment_parent" id="comment_parent" value="0" />
                                                </p>
                                            </form>
                                        </div>
                                    </>
                                );	
                            }}
                            </Formik>
                        )}
                        </ApolloConsumer>
                        }
                

                        
                    </div>
                    <div className="row_custom_small Legal-form">
                        <RichText field={this.props.fields && this.props.fields.PrivacyLegalText} />
                    </div>
                </div>
            </div>


            <div className="clear"></div>
        </div>
    </div>
</div>
            </>
        )
    }
}

export default withSitecoreContext()(RatingReviews);
