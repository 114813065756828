import React from 'react';
import {Image, RichText, withSitecoreContext} from '@sitecore-jss/sitecore-jss-react';
import ListProductVariant from '../ListProductVariant';
import StarRating from '../StarRating';
import productMas from '../../assets/img/product-mas.svg';
import {NavLink} from "react-router-dom";
import i18next from 'i18next';
import ReactComponent from "../../ReactComponent";
import {loader as gqlLoader} from "graphql.macro";
import {DotLoader} from "react-spinners";
import {Query} from "react-apollo";
import {css} from "@emotion/react";

const ProductGridQuery = gqlLoader('./query.graphql');
const override = css`
  margin: auto;
`;

class ProductGridItem extends ReactComponent {

  constructor(props) {
    super(props);
    this.state = {
      isLoaded:  false
    }
  }

  getRating(data) {
    let sumRating = 0;
    let numRating = 1;
    let averageRate = 0;
    data.productrating && data.productrating.results.items && data.productrating.results.items.map((rating) => {
        numRating = data.productrating.results.items.length;
        sumRating += rating.item.rating.numberValue;
    });
    averageRate = sumRating / numRating;

    return averageRate;
  }

  render() {
    const productId = this.props.product.item !== null ? this.props.product.item.id : '';

    return (
        <Query query={ProductGridQuery} variables={
          {
              indexname: this.indexName,
              rootpath: '/sitecore/content/' + this.appName + '/',
              rootpathrating: '/sitecore/content/' + this.appName + '/Content/Rating and Reviews',
              productid: productId
          }
        }>
              {({ loading, error, data}) => {
                  if (loading)
                      return (
                          <div className='sweet-loading' style={{marginTop: '50px', marginBottom: '50px'}}>
                              <DotLoader
                                  css={override}
                                  sizeUnit={"px"}
                                  size={50}
                                  color={'#8986ca'}
                                  loading={!this.state.isLoaded}
                              />
                          </div>
                      );
                  if (error)
                      return (
                          <div>Error: {error.message}</div>
                      );

                  const product = this.props.product;
                  const columnsize = this.props.columnsize;

                  return (
                      product.item !== null &&
                      <>
                          <div className={`col-md-${columnsize.value === "4" || columnsize === null ? "3" : "4"} Producto-item`} id={(product.item.id).substr(5)}>
                              <button type="button" className="plus__custom" data-toggle="modal"
                                      data-target={`#__${(product.item.id).substr(5)}`}>
                                  <i className="fas fa-search-plus"></i>
                              </button>

                              {product.item.url && product.item.url.startsWith("http") === true ?
                                  <a href={product.item.url} className="link__custom__item">
                                    <span className="img-cn">
                                        <img src={product.item.image1 && product.item.image1.src}
                                               className="img_custom_full"/>
                                    </span>
                                    <strong className="title-cn">{product.item.title.value}</strong>
                                      <p className="star-cn">
                                          <StarRating value={this.getRating(data)}/>
                                      </p>
                                      <button type="button"
                                              className="btn btn_custom_o">{i18next.t("SeeMoreButton")}</button>
                                  </a>
                                  :
                                  <NavLink to={product.item.url} className="link__custom__item">
                                    <span className="img-cn">
                                        <img src={product.item.image1 && product.item.image1.src} 
                                               className="img_custom_full"/>
                                    </span>
                                      <strong className="title-cn">{product.item.title.value}</strong>
                                      <p className="star-cn">
                                          <StarRating value={this.getRating(data)}/>
                                      </p>
                                      <button type="button"
                                              className="btn btn_custom_o">{i18next.t("SeeMoreButton")}
                                      </button>
                                  </NavLink>
                              }
                              <div className="modal fade" id={`__${(product.item.id).substr(5)}`} tabindex="-1"
                                   role="dialog" aria-labelledby="label_of_modal" aria-hidden="true">
                                  <div className="modal-dialog modal-dialog-centered modal-xl">
                                      <div className="modal-content">
                                          <button type="button" className="close text-right" data-dismiss="modal"
                                                  aria-label="Close">
                                              <span aria-hidden="true">×</span>
                                          </button>
                                          <div className="modal-body">
                                              <div className="col-md-5 text-center">
                                                  <img src={product.item.image1.src} alt={product.item.image1.alt} className="img_custom_full"/>
                                              </div>
                                              <div className="col-md-7 text-left">
                                                  <h2 className="popup-title_item">{product.item.title.value}</h2>
                                                  <span className="litro__custom">
                                                      <ListProductVariant productid={product.item.id} />
                                                    </span>
                                                  <p className="star-cn">
                                                      <StarRating value={this.getRating(data)}/>
                                                  </p>
                                                  <RichText field={product.item.longDescription} className="pop__description"/>
                                                  {data && data.productvariant && data.productvariant.results.items.length > 0 && (
                                                      data.productvariant.results.items[0].item !== null &&
                                                      data.productvariant.results.items[0].item.productVariantLink.url.startsWith("http") === true ?
                                                          <a href={data.productvariant.results.items[0].item.productVariantLink.url}
                                                             className="btn btn_custom_o"
                                                             target={"_blank"}>{i18next.t("BuyButton")}</a>
                                                          :
                                                          data.productvariant.results.items[0].item !== null &&
                                                              <NavLink to={data.productvariant.results.items[0].item.productVariantLink.url}
                                                                       className="btn btn_custom_o"
                                                                       target="_blank">{i18next.t("BuyButton")}</NavLink>
                                                    )
                                                  }
                                              </div>
                                          </div>
                                      </div>
                                  </div>
                              </div>
                          </div>
                      </>
                  );
              }}
          </Query>
        )
  }
}

export default withSitecoreContext()(ProductGridItem);
