import React from 'react';
import { Image,RichText } from '@sitecore-jss/sitecore-jss-react';
import './twoImagesOneText.css';

const TwoImagesOneText = (props) => {
  
  let imageLarge = <img src={props.fields.LargeImage && props.fields.LargeImage.value.src} />;
  let contentAndImageUnderText = (<div>
                                    <div>
                                        <p>
                                        <RichText field={props.fields.TextContent}/>
                                        </p>
                                        <p>
                                            <img src={props.fields.ImageUnderTextContent && props.fields.ImageUnderTextContent.value.src} className="img_custom_full"/>
                                        </p>
                                    </div>
                                  </div>
                                  );
  return(
    <div className={ props.fields.SwitchLargeImageToLeft.value !== "1" ? "row_custom_small display_my_img_txt display__img__text" : "row_custom_small display_my_img_txt"}>
        <div className={ props.fields.SwitchLargeImageToLeft.value ==="1" ? "col-md-5 c-l img-cn" : "col-md-7 c-l text-cn"}>
          { props.fields.SwitchLargeImageToLeft.value ==="1" ? imageLarge : contentAndImageUnderText }
        </div>
        <div className={ props.fields.SwitchLargeImageToLeft.value ==="1" ? "col-md-7 c-r text-cn" : "col-md-5 c-r img-cn"} >
          { props.fields.SwitchLargeImageToLeft.value ==="1" ? contentAndImageUnderText :  imageLarge}
        </div>
    </div>
  );
}



export default TwoImagesOneText;
