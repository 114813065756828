import React from 'react';
import {withSitecoreContext, Text, Image} from '@sitecore-jss/sitecore-jss-react';
import { css } from '@emotion/react';
import { DotLoader } from 'react-spinners';
import RowSeparator from '../RowSeparator';
import {NavLink} from "react-router-dom";
import i18next from 'i18next';
import ReactComponent from "../../ReactComponent";
import {loader as gqlLoader} from "graphql.macro";
import {Query} from "react-apollo";
import './subCategories.css';

const SubCatByCatQuery = gqlLoader('./query.graphql');
const override = css`
  margin: auto;
`;

class SubCategoriesByCategory extends ReactComponent {

    constructor(props) {
        super(props);
        this.state = {
            isLoaded: false
        };
    }

    render() {
        return (
            <Query query={SubCatByCatQuery} variables={{
                indexname: this.indexName, rootpath: '/sitecore/content/' + this.appName + '/'
            }}>
                {({loading, error, data}) => {
                    if (loading)
                        return (
                            <div className='sweet-loading' style={{marginTop: '50px', marginBottom: '50px'}}>
                                <DotLoader
                                    css={override}
                                    sizeUnit={"px"}
                                    size={50}
                                    color={'#8986ca'}
                                    loading={!this.state.isLoaded}
                                />
                            </div>
                        );
                    if (error)
                        return (
                            <div>Error: {error.message}</div>
                        );
                    return (
                        <>
                            <Text field={this.props.fields.Title} tag="h1"/>
                            {

                                data && data.product.results.items.map(function (category, idx) {
                                    return (
                                        <>
                                            <div className="row_custom_small" key={idx}>
                                                <div className="col-md-12">
                                                    <Text field={category.item && category.item.title} tag="h1"/>
                                                </div>
                                            </div>
                                            <div className="row_custom_small list_cat_product__custom">
                                                {
                                                    category.item && category.item.children.map(function (subcategory) {
                                                        if (subcategory.id != null) {
                                                            return (
                                                                <>
                                                                    {subcategory.url && subcategory.url.startsWith("http") === true ?
                                                                        <a href={subcategory.url}
                                                                           className="col-md-6 item_product_category">
                                                                            <img className="img-fluid img_custom_full"
                                                                                   src={subcategory && subcategory.image && subcategory.image.src}
                                                                                   alt=""/>
                                                                            <Text field={subcategory && subcategory.title}
                                                                                  tag="h2"/>
                                                                            <p className="little_txt">
                                                                                {subcategory.shdesc.value}
                                                                            </p>
                                                                            <button type="button"
                                                                                    className="btn btn-oro">{i18next.t("CategoryButton")}</button>
                                                                        </a>
                                                                        :
                                                                        <NavLink to={subcategory.url}
                                                                                 className="col-md-6 item_product_category">
                                                                            <img className="img-fluid img_custom_full"
                                                                                   src={subcategory && subcategory.image&& subcategory.image.src}
                                                                                   alt=""/>
                                                                            <Text field={subcategory && subcategory.title}
                                                                                  tag="h2"/>
                                                                            <p className="little_txt">
                                                                                {subcategory.shdesc.value}
                                                                            </p>
                                                                            <button type="button"
                                                                                    className="btn btn-oro">{i18next.t("CategoryButton")}</button>
                                                                        </NavLink>
                                                                    }
                                                                </>
                                                            );
                                                        }
                                                    })
                                                }
                                            </div>
                                            {data.product.results.items && (data.product.results.items.length != idx + 1) &&
                                            <RowSeparator/>}
                                        </>
                                    );

                                })
                            }
                        </>
                    )
                }
            }
            </Query>
        )
    }
}

export default withSitecoreContext()(SubCategoriesByCategory);
