import React from 'react';
import { Image, Text, RichText, withSitecoreContext } from '@sitecore-jss/sitecore-jss-react';


const TitleAndDescriptionAndImage = (props) => {
    const myColor = props.field !== undefined ? props.fields.TitleColor.fields.cssColor.value : {};

    return (
        <div className="row_custom_small box-1 tadai">
            <div className="row_custom_small section3">
              <div className="col-md-12 pad text-left">
                  {
                      props.field &&
                      <font color={`${myColor}`}>
                          {props.fields.Title && props.fields.Title.value !== "" &&
                            <Text field={props.fields.Title} tag="h1"/>
                          }
                          {props.fields.ImageLocation && props.fields.Image && props.fields.ImageLocation.fields.title.value === 'Before Description' && (
                            <img src={props.fields.Image && props.fields.Image.value.src} className="img_custom_full"/>
                          )}
                          {props.fields.Description && props.fields.Description.value !== "" && <blockquote> <RichText field={props.fields.Description} tag="p"/> </blockquote> }
                      </font>
                  }
                  {props.fields && props.fields.ImageLocation && props.fields.Image && props.fields.ImageLocation.fields.title.value !== 'Before Description' && (
                    <img src={props.fields.Image && props.fields.Image.value.src} className="img_custom_full"/>
                  )}                  
                </div>
            </div>
        </div>
    );
};

export default withSitecoreContext()(TitleAndDescriptionAndImage);
