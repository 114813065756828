import React from 'react';
import { Placeholder } from '@sitecore-jss/sitecore-jss-react';
import './blockOfText.css';

const BlocksOfText = (props) => (
  <div className="container margin_custom custom_row_block">
    <Placeholder name="row_custom_small-block-placeholder" rendering={props.rendering} />
  </div>
);

export default BlocksOfText;
