import React from "react";
import Paging from "../../Utils/Paging";
import ProductGridItem from "../ProductGridItem/index.js";
import { withSitecoreContext } from "@sitecore-jss/sitecore-jss-react";
import ReactComponent from "../../ReactComponent";

class FilteredProductList extends ReactComponent {
  constructor(props) {
    super(props);
    this.state = {
      itemdata: [],
      filters: [],
      view: "list",
      itemsPerPage: "12",
      sorting: [],
      keyword: "",
      init: true,
    };
    this.onItemsChange = this.onItemsChange.bind(this);
    this.handleView = this.handleView.bind(this);
    this.handleItemsPerPage = this.handleItemsPerPage.bind(this);
    this.resolveSorting = this.resolveSorting.bind(this);
  }

  resolveSorting(value) {
    let sorting = [];
    switch (value) {
      case "default":
        sorting = [];
        break;
      case "ratingUp":
        sorting = ["rating", "ASC"];
        break;
      case "ratingDown":
        sorting = ["rating", "DESC"];
        break;
      case "newnessUp":
        sorting = ["__smallupdateddate", "ASC"];
        break;
      case "newnessDown":
        sorting = ["__smallupdateddate", "DESC"];
        break;
      case "priceUp":
        sorting = ["saleprice", "ASC"];
        break;
      case "priceDown":
        sorting = ["saleprice", "DESC"];
        break;
      default:
        sorting = [];
        break;
    }
    return sorting;
  }

  componentDidMount() {
    let value = localStorage.getItem("ProductListSorting") || "default";
    let sorting = this.resolveSorting(value);

    const url = window.location.href;

    var filters = [];
    if (
      this.props.sitecoreContext.route.fields.PfTag != null &&
      this.props.sitecoreContext.route.fields.PfTag != "undefined"
    ) {
      filters.push(
        '{name: "tags", value: "' +
          this.props.sitecoreContext.route.fields.PfTag.id
            .replace(/-/g, "")
            .toLowerCase() +
          '"},'
      );
      this.setState({
        init: true,
        filters: filters,
      });
    }
    let filterId = "";
    if (url.includes("?tagId=")) {
      filterId = url.substring(url.indexOf("=") + 1);

      filters.push(
        '{name: "tags", value: "' +
          filterId.replace(/-/g, "").toLowerCase() +
          '"},'
      );
      this.setState({
        init: true,
        filters: filters,
      });
    }
  }

  onItemsChange(itemdata) {
    this.setState({
      itemdata: itemdata,
    });
    if (itemdata && itemdata.data)
      this.props.onFacetsChange(itemdata.data.searchFacets.facets);
  }

  handleView() {
    let view = "";
    if (this.state.view === "list") {
      view = "grid";
      localStorage.setItem("ProductListDisplayType", "grid");
    } else if (this.state.view === "grid") {
      view = "list";
      localStorage.setItem("ProductListDisplayType", "list");
    }
    this.setState({
      view: view,
    });
  }

  handleItemsPerPage(e) {
    localStorage.setItem("ProductListItemsPerPage", e.currentTarget.value);
    this.setState({
      itemsPerPage: e.currentTarget.value,
    });
  }

  componentDidUpdate(prevProps, prevState) {
    if (prevProps.categoriesSession !== this.props.categoriesSession) {
      let filters = [];

      for (var filter of this.props.categoriesSession) {
        filters.push('{name: "tags", value: "' + filter.toLowerCase() + '"},');
      }
      this.setState({
        filters: filters,
        init: true,
      });
    }
  }

  render() {
    let QUERY =
      'query={ searchFacets:search( index:"' +
      this.indexName +
      '" fieldsEqual: [{name: "_templatename", value: "ProductRoute"}, ##fieldsEqual ], keyword: "##keyword",  facetOn: ["tags"], rootItem: "/sitecore/content/' +
      this.appName +
      '/") { ' +
      "facets { name values { count value " +
      'item { id ... on ProductTagTemplate { title { value } } } } } } search:search( index:"' +
      this.indexName +
      '" first: ##first, after: "##after", fieldsEqual: [{name: "_templatename", value: "ProductRoute"}, ##fieldsEqual ], keyword: "##keyword",  ##sortBy  rootItem: "/sitecore/content/' +
      this.appName +
      '/") { results { totalCount pageInfo { startCursor endCursor hasNextPage hasPreviousPage } items { item { id url name ... on ProductDetails { title { value } shortDescription: productShortDescription { value } longDescription:productLongDescription { value } image1 { src value alt } image2 { src value alt } image3 { src value alt } image4 { src value alt } rating { numberValue } price { numberValue } ' +
      " tags { targetItems { id ... on ProductTagTemplate { title { value } } } } salePrice { numberValue } } } } } } }";

    return (
      <>
        <div className="container margin_custom p-0">
          <div className="row_custom_small list__product__item">
            {this.state.itemdata &&
              this.state.itemdata.data &&
              this.state.itemdata.data.search.results.items.map((product) => (
                <ProductGridItem
                  product={product}
                  listView={this.state.view === "list" ? true : false}
                  columnsize={this.props.fields.GridItemsPerRow}
                />
              ))}
          </div>
          <nav className="commerce-pagination col-md-12">
            {this.state.init && (
              <Paging
                onItemsChange={this.onItemsChange}
                query={QUERY}
                showIndexCount={true}
                solrFields={this.state.filters}
                itemsPerPage={this.state.itemsPerPage}
                sort={this.state.sorting}
                keyword={this.props.keyword}
              />
            )}
          </nav>
        </div>
      </>
    );
  }
}

export default withSitecoreContext()(FilteredProductList);
