import React from 'react';
import context from "./Utils/Context";

class ReactComponent extends React.Component{

    indexName;
    appName;
    getGraphqlEndpoint;

    constructor(props){
        super(props);
        this.indexName = context.getIndexName(props);
        this.appName = this.props.sitecoreContext.site.name;
        this.getGraphqlEndpoint = context.getGraphQLEndPoint();
    }

}

export default ReactComponent;