import React from 'react';
import { RichText, Text, Image, Link, withSitecoreContext, isExperienceEditorActive, Placeholder } from '@sitecore-jss/sitecore-jss-react';
import 'react-responsive-carousel/lib/styles/carousel.min.css';
import './carouselProductOver.css';
import './productSummary.css';
import { Carousel } from 'react-responsive-carousel';
import Lightbox from 'react-images';
import { css } from '@emotion/react';
import { DotLoader } from 'react-spinners';
import zoomIcon from '../../assets/img/product-mas.svg';
import ListProductVariant from '../ListProductVariant';
import ProductRating from '../ProductRating';
import { NavLink } from "react-router-dom";
import i18next from 'i18next';
import ReactComponent from "../../ReactComponent";
import { loader as gqlLoader } from "graphql.macro";
import { Query } from "react-apollo";

const ProductSummaryQuery = gqlLoader('./query.graphql');
const override = css`
  margin: auto;
`;

class ProductSummary extends ReactComponent {

  constructor(props) {
    super(props);
    this.onClose = this.onClose.bind(this);
    this.gotoPrevious = this.gotoPrevious.bind(this);
    this.gotoNext = this.gotoNext.bind(this);
    this.onClickThumbnail = this.onClickThumbnail.bind(this);
    this.openLightBox = this.openLightBox.bind(this);
    this.state = {
      error: null,
      isLoaded: false,
      photoIndex: 0,
      isOpen: false,
      showMessage: "",
      productVariantURL: "",
    };
  }

  openLightBox(photoIndex) {
    this.setState({
      photoIndex: photoIndex,
      isOpen: true,
    })
  }

  handleChange(event) {
    this.setState({ isOpen: true, photoIndex: event })
  };
  onClose() {
    this.setState({ isOpen: false })
  }
  gotoNext() {
    this.setState({ photoIndex: this.state.photoIndex + 1 })
  }
  gotoPrevious() {
    this.setState({ photoIndex: this.state.photoIndex - 1 })
  }

  onClickThumbnail(event) {
    this.setState({ photoIndex: event })
  }

  render() {
    const Image1 = (itemImage1) => {
      if (itemImage1.value && itemImage1.value.src && itemImage1.value.src.length > 0) {
        return (
          <div>
            <img src={this.props.sitecoreContext.route.fields && this.props.sitecoreContext.route.fields.image1.value.src} alt="" className="img_custom_full" />
          </div>
        )
      }
      else { return <></> }
    };
    const Image2 = (itemImage2) => {
      if (itemImage2.value && itemImage2.value.src && itemImage2.value.src.length > 0) {
        return (
          <div>
            <img src={this.props.sitecoreContext.route.fields && this.props.sitecoreContext.route.fields.image2.value.src} alt="" className="img_custom_full" />
          </div>
        )
      }
      else { return <></> }
    };
    const Image3 = (itemImage3) => {
      if (itemImage3.value && itemImage3.value.src && itemImage3.value.src.length > 0) {
        return (
          <div>
            <img src={this.props.sitecoreContext.route.fields && this.props.sitecoreContext.route.fields.image3.value.src} alt="" className="img_custom_full" />
          </div>
        )
      }
      else { return <></> }
    };

    const Image4 = (itemImage4) => {
      if (itemImage4.value && itemImage4.value.src && itemImage4.value.src.length > 0) {
        return (
          <div>
            <img src={this.props.sitecoreContext.route.fields && this.props.sitecoreContext.route.fields.image4.value.src} alt="" className="img_custom_full" />
          </div>
        )
      }
      else { return <></> }
    };

    let imageArray = [];

    if (this.props.sitecoreContext.route.fields.image1.value && this.props.sitecoreContext.route.fields.image1.value.src != null) {
      imageArray.push({ src: this.props.sitecoreContext.route.fields.image1.value.src })
    }

    if (this.props.sitecoreContext.route.fields.image2.value && this.props.sitecoreContext.route.fields.image2.value.src != null) {
      imageArray.push({ src: this.props.sitecoreContext.route.fields.image2.value.src })
    }

    if (this.props.sitecoreContext.route.fields.image3.value && this.props.sitecoreContext.route.fields.image3.value.src != null) {
      imageArray.push({ src: this.props.sitecoreContext.route.fields.image3.value.src })
    }

    if (this.props.sitecoreContext.route.fields.image4.value && this.props.sitecoreContext.route.fields.image4.value.src != null) {
      imageArray.push({ src: this.props.sitecoreContext.route.fields.image4.value.src })
    }

    let ngRel = this.props.sitecoreContext.route.fields.relevantAdvantages.length <= 1 ? "col-md-12" : "col-md-3";

    return(
      <div className="row_custom_small summary-container">
        <div className="col-sm-5 entry-image carousel_custom_product_5">
          <div className="single-product-images">
            <a className="product--gallery__trigger" onClick={() => this.openLightBox(0)}><img src={zoomIcon} /></a>
            {isExperienceEditorActive() && (
              <div className="product-thumbnail">
                <img src={this.props.sitecoreContext.route.fields && this.props.sitecoreContext.route.fields.image1&& this.props.sitecoreContext.route.fields.image1.value.src} className="img_custom_full" />
                <img src={this.props.sitecoreContext.route.fields && this.props.sitecoreContext.route.fields.image2&& this.props.sitecoreContext.route.fields.image2.value.src} className="img_custom_full" />
                <img src={this.props.sitecoreContext.route.fields && this.props.sitecoreContext.route.fields.image3&& this.props.sitecoreContext.route.fields.image3.value.src} className="img_custom_full" />
                <img src={this.props.sitecoreContext.route.fields && this.props.sitecoreContext.route.fields.image4&& this.props.sitecoreContext.route.fields.image4.value.src} className="img_custom_full" />
              </div>
            )}
            {!isExperienceEditorActive() && (
              <Carousel showStatus={false} showIndicators={false} onClickItem={this.handleChange.bind(this)}>
                {Image1(this.props.sitecoreContext.route.fields.image1)}
                {Image2(this.props.sitecoreContext.route.fields.image2)}
                {Image3(this.props.sitecoreContext.route.fields.image3)}
                {Image4(this.props.sitecoreContext.route.fields.image4)}
              </Carousel>
            )}
            {this.state.isOpen && (
              <Lightbox
                images={imageArray}
                isOpen={this.state.isOpen}
                onClickPrev={this.gotoPrevious}
                onClickNext={this.gotoNext}
                onClose={this.onClose}
                backdropClosesModal={true}
                currentImage={this.state.photoIndex}
                enableKeyboardInput={true}
                showImageCount={false}
                showThumbnails={true}
                onClickThumbnail={this.onClickThumbnail.bind(this)}
              />
            )}
          </div>
        </div>
        <Query query={ProductSummaryQuery} variables={{ indexname: this.indexName, rootpath: this.props.sitecoreContext.route.itemId.split('-').join('').toLowerCase()}}>
          {({ loading, error, data }) => {
            if (loading)
              return (
                <div className='sweet-loading' style={{ marginTop: '50px', marginBottom: '50px' }}>
                  <DotLoader
                    css={override}
                    sizeUnit={"px"}
                    size={50}
                    color={'#8986ca'}
                    loading={!this.state.isLoaded}
                  />
                </div>
              );
            if (error)
              return (
                <div>Error: {error.message}</div>
              );
            if (data && data.product && data.product.results.items.length === 0)
              return (
                  <div>Product Summary: No product Id found</div>
              );
            return (
              data.product.results.items.map((product, idx) => (
                <React.Fragment key={idx}>
                  <div className="col-sm-7 entry-summary carousel_custom_product_7">
                    <div className="summary">
                      <h1 className="product_title entry-title"><Text field={this.props.sitecoreContext.route.fields && this.props.sitecoreContext.route.fields.title} /></h1>
                      <ListProductVariant productid={this.props.sitecoreContext.itemId} />
                      <div className="commerce-product-rating">
                        <ProductRating productid={product} />
                        <a href="#viewForm" className="commerce-product-review-link d-inline-flex mt-2 mr-3 mb-2" rel="nofollow">
                          <strong><u>{i18next.t("ViewAllReviewsLabel")}</u></strong>
                        </a>
                        <a href="#reviewForm" className="commerce-product-review-link d-inline-flex mb-2" rel="nofollow">
                          <strong><u>{i18next.t("WriteReviewLabel")}</u></strong>
                        </a>
                      </div>
                      <div className="product-excerp mt-4">
                        <p>
                          <RichText field={this.props.sitecoreContext.route.fields && this.props.sitecoreContext.route.fields.productShortDescription} />
                        </p>
                      </div>
                      <div className="accordion" id="product_accordion">
                        <div className="card">
                          <div className="card-header" id="headingOne">
                            <h1 className="mb-0">
                              <button className="btn btn-link collapsed text-uppercase text-left mr-2" type="button" data-toggle="collapse" data-target="#hairType" aria-expanded="false" aria-controls="hairType">
                                {i18next.t("SuitableForAllHairTypesLabel")} <i className="fas fa-plus" /><i className="fas fa-minus" />
                              </button>
                            </h1>
                          </div>
                          <div id="hairType" className="collapse" aria-labelledby="headingOne" data-parent="#product_accordion">
                            <div className="card-body">
                              <span className="double_cat">
                                <Text field={this.props.sitecoreContext.route.fields && this.props.sitecoreContext.route.fields.perfectFor} tag="span"/>
                              </span>
                            </div>
                          </div>
                        </div>
                        <div className="card">
                          <div className="card-header" id="headingThree">
                            <h1 className="mb-0">
                              <button className="btn btn-link collapsed text-uppercase text-left mr-2" type="button" data-toggle="collapse" data-target="#use" aria-expanded="false" aria-controls="use">
                                {i18next.t("HowToUseLabel")} <i className="fas fa-plus" /><i className="fas fa-minus" />
                              </button>
                            </h1>
                          </div>
                          <div id="use" className="collapse" aria-labelledby="headingThree" data-parent="#product_accordion">
                            <div className="card-body">
                              <RichText field={this.props.sitecoreContext.route.fields && this.props.sitecoreContext.route.fields.howtouse}/>
                            </div>
                          </div>
                        </div>
                        <div className="card">
                          <div className="card-header" id="headingThree">
                            <h1 className="mb-0">
                              <button className="btn btn-link collapsed text-uppercase text-left mr-2" type="button" data-toggle="collapse" data-target="#ingredient" aria-expanded="false" aria-controls="ingredient">
                                {i18next.t("IngredientsLabel")} <i className="fas fa-plus" /><i className="fas fa-minus" />
                              </button>
                            </h1>
                          </div>
                          <div id="ingredient" className="collapse" aria-labelledby="headingThree" data-parent="#product_accordion">
                            <div className="card-body">
                              <span className="key_ingre">
                                {product.item.productIngredients !== undefined &&
                                  product.item.productIngredients.targetItems.length > 0 &&
                                  product.item.productIngredients.targetItems.map((ing, index) => (
                                    <span>
                                      {ing.title.value}
                                      {index < product.item.productIngredients.targetItems.length - 1 && <>,&nbsp;</>}
                                    </span>
                                  ))
                                }
                              </span>
                            </div>
                          </div>
                        </div>
                        <div className="card">
                          <div className="card-header" id="headingfour">
                            <h1 className="mb-0">
                              <span className="btn btn-link collapsed text-uppercase text-left mr-2 nohover">
                                {i18next.t("WhereToBuyLabel")}
                              </span>
                            </h1>
                          </div>
                        </div>
                      </div>
                      <div className="dropdownCustom">
                        <Placeholder name="dropdown-ecommerce" rendering={this.props.rendering} />
                        {this.props.sitecoreContext.route.fields.findSalon &&
                          (this.props.sitecoreContext.route.fields.findSalon.value.linktype !== undefined &&
                              this.props.sitecoreContext.route.fields.findSalon.value.href.startsWith("http") === true ?
                                  <Link field={this.props.sitecoreContext.route.fields.findSalon} className={"btn btn-secondary text-uppercase findSalon"} />
                                  :
                              this.props.sitecoreContext.route.fields.findSalon.value.linktype !== undefined &&
                                  <NavLink to={this.props.sitecoreContext.route.fields.findSalon} className={"btn btn-secondary text-uppercase findSalon"}>
                                    {this.props.sitecoreContext.route.fields.findSalon.value.text}
                                  </NavLink>
                          )
                        }
                      </div>
                    </div>
                    <div className="col-md-12 linksProd">
                      <div className="col align-self-end text-right socialLink">
                        {this.props.sitecoreContext.route.fields.fb &&
                          this.props.sitecoreContext.route.fields.fb.value.href !== "" &&
                          (this.props.sitecoreContext.route.fields.fb.value.linktype !== undefined &&
                            this.props.sitecoreContext.route.fields.fb.value.href.startsWith("http") === true ?
                            <Link field={this.props.sitecoreContext.route.fields.fb} target={"_blank"}><i className="fab fa-facebook-f" /></Link>
                            :
                            <NavLink to={this.props.sitecoreContext.route.fields.fb} ><i className="fab fa-facebook-f" /></NavLink>
                          )
                        }
                        {this.props.sitecoreContext.route.fields.twitter &&
                          this.props.sitecoreContext.route.fields.twitter.value.href !== "" &&
                          (this.props.sitecoreContext.route.fields.twitter.value.linktype !== undefined &&
                            this.props.sitecoreContext.route.fields.twitter.value.href.startsWith("http") === true ?
                            <Link field={this.props.sitecoreContext.route.fields.twitter} target={"_blank"}><i className="fab fa-twitter" /></Link>
                            :
                            <NavLink to={this.props.sitecoreContext.route.fields.twitter} ><i className="fab fa-twitter" /></NavLink>
                          )
                        }
                        {this.props.sitecoreContext.route.fields.insta &&
                          this.props.sitecoreContext.route.fields.insta.value.href !== "" &&
                          (this.props.sitecoreContext.route.fields.insta.value.linktype !== undefined &&
                            this.props.sitecoreContext.route.fields.insta.value.href.startsWith("http") === true ?
                            <Link field={this.props.sitecoreContext.route.fields.insta} target={"_blank"}><i className="fab fa-instagram" /></Link>
                            :
                            <NavLink to={this.props.sitecoreContext.route.fields.insta} target={"_blank"}><i className="fab fa-instagram" /></NavLink>
                          )
                        }
                      </div>
                    </div>
                  </div>
                </React.Fragment>
              ))
            )
          }}
        </Query>
        <div className="row_custom_small mt-4">
          <div className="col-md-12 text-center">
            <h1>
              <div>
                <RichText
                    field={this.props.sitecoreContext.route.fields && this.props.sitecoreContext.route.fields.relevantTitle}
                    tag={"span"}
                />
              </div>
            </h1>
          </div>
          <div className="col-md-12 col-xs-12 entry-relevant">
            <div className="card">
              <div className="col-md-12 col-xs-12 card-body text-center">
                <div className={"subBody"}>
                  {this.props.sitecoreContext.route.fields.relevantAdvantages &&
                  this.props.sitecoreContext.route.fields.relevantAdvantages.map((relevantItem, index) => {
                    return (
                        <div
                            className={`${ngRel} text-center relAll`}
                            key={index}
                        >
                          <img
                              src={relevantItem.fields && relevantItem.fields.Image&& relevantItem.fields.Image.value.src}
                              
                          />
                          <Text
                              field={relevantItem.fields && relevantItem.fields.Title}
                              editable={true}
                              tag={"h2"}
                          />
                          <RichText
                              field={relevantItem.fields && relevantItem.fields.ShortDescription}
                              editable={true}
                          />
                        </div>
                    );
                  })}
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    )
  }
}


export default withSitecoreContext()(ProductSummary);
