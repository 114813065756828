import React from 'react';
import { Text, isExperienceEditorActive, withSitecoreContext } from '@sitecore-jss/sitecore-jss-react';
import CarouselQouteItem from '../CarouselQouteItem';

import OwlCarousel from 'react-owl-carousel2';
import 'react-owl-carousel2/src/owl.carousel.css';
import './carouselQuote.css';

class CarouselQoutes extends React.Component {

  constructor(props) {
    super(props);
    this.state = {
      activeDot: 0
    };
    this.setActiveDot = this.setActiveDot.bind(this);
  }

  setActiveDot(dot) {
    this.setState({
      activeDot: dot
    })
    
  }

  render () {

    const options = {
      items: parseInt(this.props.fields.ItemPerSlide.value),
      dots: true,
      
      nav: false
      
    };

    const contentList = this.props.fields.QuotesList;

    const dots = [];
    
    for (let i = 0; i <= contentList.length; i++) {
      dots.push(
        <div className="owl-dot" style={{ display: "inline-block" }} key={i}>
          <a href="#" 
            className={this.state.activeDot === i ? "selected" : ""} onClick={() => this.setActiveDot(i)}>
            <span>{i}</span>
          </a>
        </div>
      );
    }

    return (
      
      <div className="section pt-7 pb-7 owl-carousel_custom">
        <div className="row_custom_small">
          <div data-wow-delay="0.3s" className="col-md-12 col-sm-12 wow fadeInUp p-0 custCarousel">
            <h1 className={"text-center"}>
              <Text field={this.props.fields && this.props.fields.Title} tag="span" className="mb-2" />
            </h1>
            <Text field={this.props.fields && this.props.fields.Subtitle} tag="p" className="text-primary text-uppercase mb-5" />
            <div className="testimonial style-4">
              {this.props.sitecoreContext && (this.props.sitecoreContext.pageState !== "preview" || isExperienceEditorActive()) && (
                <OwlCarousel options={options}>
                  {contentList &&
                      contentList.map((listItem, index) => (
                        <div key={`listItem-${index}`}>
                          <CarouselQouteItem
                            carouselLink={listItem.fields.Link}
                            carouselSlideImage={listItem.fields.CarouselSlideImage}
                            carouselLinkVideo={listItem.fields.LinkVideo}
                            carouselTitle={listItem.fields.Title}
                            carouselContent={listItem.fields.Content}
                            carouselButton={listItem.fields.Button}
                          />
                        </div>
                      ))
                  }
                </OwlCarousel>
              )}
            </div>
          </div>
        </div>
      </div>
    );
  }
}

export default withSitecoreContext()(CarouselQoutes);
