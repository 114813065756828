import React from 'react';
import { Text, RichText, withSitecoreContext, isExperienceEditorActive } from '@sitecore-jss/sitecore-jss-react';
import { css } from '@emotion/react';
import { DotLoader } from 'react-spinners';
import timelineImg from '../../assets/img/Timeline/ico-history.svg'
import i18next from 'i18next';
import ReactComponent from "../../ReactComponent";
import {loader as gqlLoader} from "graphql.macro";
import {Query} from "react-apollo";
import './timeline.css';

const TimelineQuery = gqlLoader('./query.graphql');
const override = css`
  margin: auto;
`;

class Timeline extends ReactComponent {

  constructor(props) {
    super(props);
    this.state = {
      isLoaded: false
    };
  }

  render() {
    function SortByYear(x,y) {
      if (y.item.eventYear.numberValue !== undefined) {
        return ((x.item.eventYear.numberValue === y.item.eventYear.numberValue) ? 0 : ((x.item.eventYear.numberValue > y.item.eventYear.numberValue) ? 1 : -1 ));
      }
    }

    return (
        <Query query={TimelineQuery} variables={{
          indexname: this.indexName, rootpath: '/sitecore/content/' + this.appName + '/' + this.props.sitecoreContext.route.itemId.split('-').join('').toLowerCase()
        }}>
          {({loading, error, data}) => {
            if (loading)
              return (
                  <div className='sweet-loading' style={{marginTop: '50px', marginBottom: '50px'}}>
                    <DotLoader
                        css={override}
                        sizeUnit={"px"}
                        size={50}
                        color={'#8986ca'}
                        loading={!this.state.isLoaded}
                    />
                  </div>
              );
            if (error)
              return (
                  <div>Error: {error.message}</div>
              );
            else if (data.search.results.items.length === 0)
              return (
                  isExperienceEditorActive() && (
                      <div>{i18next.t("NoTimelineFoundLabel")}</div>
                  )
              );
            return (
                <div className="history">
                  {
                    data.search.results &&
                    data.search.results.items.sort(SortByYear).map(function (event, idx) {
                      if (idx % 2 === 0) {
                        return (
                            <div className="row_custom_small changeOrder" key={idx}>
                              <div className="col-md-5 c-l type-txt">
                                <div><h2><Text field={event.item.eventTitle}/></h2>
                                  <RichText field={event.item.eventContent}/>
                                </div>
                              </div>
                              <div className="col-md-7 c-r type-num">
                                <p>
                                  <img src={timelineImg}  alt=""
                                       className="img_custom_full"/><br/>
                                  <Text field={event.item.eventYear}/>
                                </p>
                              </div>
                            </div>
                        )
                      } else {
                        return (
                            <div className="row_custom_small" key={idx}>
                              <div className="col-md-7 c-l type-num">
                                <p>
                                  <img src={timelineImg} alt=""
                                       className="img_custom_full"/><br/>
                                  <Text field={event.item.eventYear}/>
                                </p>
                              </div>
                              <div className="col-md-5 c-r type-txt">
                                <div><h2><Text field={event.item.eventTitle}/></h2>
                                  <RichText field={event.item.eventContent}/>
                                </div>
                              </div>
                            </div>
                        )
                      }
                    })
                  }
                </div>
            )
          }}
        </Query>
    )
  }
}

export default withSitecoreContext()(Timeline);
