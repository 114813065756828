import React from 'react';
import { Text, RichText, Image } from '@sitecore-jss/sitecore-jss-react';
import './rowBlock.css';

class RowBlock extends React.Component {

  constructor(props) {
    super(props);
  }

  render() {
    let leftcssStyle = "col-md-6 c-l img-cn";
    let rightcssStyle = "col-md-6 c-r text-cn";

    if(this.props.fields.blockStyle !== null) {
      leftcssStyle = this.props.fields.blockStyle.fields.leftcssStyle.value
      rightcssStyle = this.props.fields.blockStyle.fields.rightcssStyle.value
    }
      
    if(leftcssStyle.includes("text-cn")) {
      return(
        <div className={`${this.props.fields.blockStyle !== null && rightcssStyle == "col-md-6 c-r img-cn" ? "row_custom_small item__row__custom inverse__custom" : "row_custom_small item__row__custom"}`}>
          <div className={leftcssStyle}>
            <div>
              <div>
                <h1><Text field={this.props.fields && this.props.fields.blockTitle} /></h1>
                <RichText field={this.props.fields && this.props.fields.blockContent}/>
              </div>
            </div>
          </div>
          <div className={rightcssStyle}>
            <img src={this.props.fields && this.props.fields.blockImage&& this.props.fields.blockImage.value.src} className="img_custom_full"/>
          </div>
        </div>
      )
    }
    else {
      return(
        <div className={`${this.props.fields.blockStyle !== null && rightcssStyle == "col-md-6 c-r img-cn" ? "row_custom_small item__row__custom inverse__custom" : "row_custom_small item__row__custom"}`}>
          <div className={leftcssStyle}>
            <img src={this.props.fields && this.props.fields.blockImage&& this.props.fields.blockImage.value.src} className="img_custom_full"/>
          </div>
          <div className={rightcssStyle}>
            <div>
              <div className={"title_row_block"}>
                <h1><Text field={this.props.fields && this.props.fields.blockTitle} /></h1>
                <RichText field={this.props.fields && this.props.fields.blockContent} className={"desc_row_block"}/>
              </div>
            </div>
          </div>          
        </div>
      )      
    }
  }
}

export default RowBlock;
