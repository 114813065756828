import React from 'react';
import { withSitecoreContext } from '@sitecore-jss/sitecore-jss-react';
import StarRating from '../StarRating';
import ReactComponent from "../../ReactComponent";
import {loader as gqlLoader} from "graphql.macro";
import {css} from "@emotion/react";
import {DotLoader} from "react-spinners";
import {Query} from "react-apollo";

const ProductRatingQuery = gqlLoader('./query.graphql');
const override = css`
  margin: auto;
`;

class ProductRating extends ReactComponent {

  constructor(props) {
    super(props);
    this.state = {
      isLoaded: false
    };
  }

  getRating(data) {
    let sumRating = 0;
    let numRating = 1;
    let averageRate = 0;
    data && data.productrating && data.productrating.results.items && data.productrating.results.items.map((rating) => {
      numRating = data.productrating.results.items.length;
      sumRating += rating.item.rating.numberValue;
    });
    averageRate = sumRating / numRating;

    return averageRate;
  }

  render() {

    return(
      <Query query={ProductRatingQuery} variables={{indexname: this.indexName, productid: this.props.sitecoreContext.route.itemId.split('-').join('').toLowerCase(), rootpath: '/sitecore/content/' + this.appName + '/Content/Rating and Reviews'}}>
        {({ loading, error, data}) => {
          if (loading)
            return (
              <div className='sweet-loading' style={{marginTop: '50px', marginBottom: '50px'}}>
                <DotLoader
                  css={override}
                  sizeUnit={"px"}
                  size={50}
                  color={'#8986ca'}
                  loading={!this.state.isLoaded}
                />
              </div>
            );
          if (error)
            return (
                <div>Error: {error.message}</div>
            );
          return (
            <StarRating value={this.getRating(data)}/>
          )
        }}
    </Query>
    )
  }
}

export default withSitecoreContext()(ProductRating);
