import React from 'react';
import { Image, Text, RichText, Link } from '@sitecore-jss/sitecore-jss-react';
import NavLink from "react-router-dom/es/NavLink";
import './carouselQuoteItem.css';

const CarouselQouteItem = (props) => {
    let carouselItem = [];

    if(props && props.carouselLink && props.carouselLink.value.href) {
        if(props && props.carouselLink && props.carouselLink.value && props.carouselLink.value.href.startsWith("http") === true){
            carouselItem.push(<a href={props && props.carouselLink && props.carouselLink.value && props.carouselLink.value.href}><Image field={props && props.carouselSlideImage} editable={"true"} className="img_custom_full"/></a>);
        }else{
            carouselItem.push(<NavLink to={props && props.carouselLink && props.carouselLink.value && props.carouselLink.value.href}><Image field={props && props.carouselSlideImage} editable={"true"} className="img_custom_full"/></NavLink>);
        }
    } else {
        carouselItem.push(<Image field={props && props.carouselSlideImage} editable={"true"} className="img_custom_full"/>);
    }

    let Screen;
    let isTop;
    if (typeof window !== 'undefined') {
        Screen = window.screen.width;
        if (Screen < 321) {
            isTop = 28
        } else if (Screen > 322 && Screen <= 375) {
            isTop = 32
        } else if (Screen >= 376 && Screen <= 425) {
            isTop = 36
        } else if(Screen >= 426 && Screen <= 769){
            isTop = 50
        }
    }

    return(
        <div className="testimonial-wrap">
            {Object.keys(carouselItem[0].props.field.value).length !== 0 ?
                <>
                    <NavLink to={props && props.carouselButton.value && props.carouselButton.value.href} className={'btn btn_new text-uppercase custom_Slide'}>
                        {carouselItem}
                    </NavLink>

                    {/*<div className="overSlide text-left" style={{top: isTop + '%'}}>*/}
                    {/*    {props && props.carouselTitle &&*/}
                    {/*    (<Text field={props && props.carouselTitle} tag={'h2'} className={"text-uppercase"}/>)*/}
                    {/*    }*/}
                    {/*    {props && props.carouselContent &&*/}
                    {/*    (<RichText field={props && props.carouselContent} tag={'span'} />)*/}
                    {/*    }*/}
                    {/*    {props && props.carouselButton && props.carouselButton.value && props.carouselButton.value.href !== '' ?*/}
                    {/*        (props.carouselButton && props.carouselButton.value && props.carouselButton.value.href.startsWith("http") === true ?*/}
                    {/*            <Link field={props && props.carouselButton} target={"_blank"} className={'btn btn_new text-uppercase'}/>*/}
                    {/*            :*/}
                    {/*            <NavLink to={props && props.carouselButton.value && props.carouselButton.value.href} className={'btn btn_new text-uppercase'}>*/}
                    {/*                {props && props.carouselButton && props.carouselButton.value && props.carouselButton.value.text}*/}
                    {/*            </NavLink>*/}
                    {/*        )*/}
                    {/*        :*/}
                    {/*        ''*/}
                    {/*    }*/}
                    {/*</div>*/}

                </>
                :
                <>
                    {props && props.carouselLinkVideo && props.carouselLinkVideo.value && props.carouselLinkVideo.value.linktype === 'external' &&
                    <iframe width="100%" height="500px" src={(props && props.carouselLinkVideo.value.url).replace('https://youtu.be/', 'https://www.youtube.com/embed/')} title={props && props.carouselLinkVideo.value && props.carouselLinkVideo.value.url}/>
                    }
                    {props && props.carouselLinkVideo && props.carouselLinkVideo.value && props.carouselLinkVideo.value.linktype === 'media' &&
                    <video className="video-fluid vid_cust" loop autoPlay muted>
                        <source src={props && props.carouselLinkVideo && props.carouselLinkVideo.value && props.carouselLinkVideo.value.href} type="video/mp4"/>
                    </video>
                    }
                </>
            }
        </div>
    );
}

export default CarouselQouteItem;
