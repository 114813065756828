import React from 'react';
import { Placeholder, VisitorIdentification } from '@sitecore-jss/sitecore-jss-react';
import Helmet from 'react-helmet';

// Using bootstrap is completely optional. It's used here to provide a clean layout for samples,
// without needing extra CSS in the sample app. Remove it in package.json as well if it's removed here.
import 'bootstrap/dist/css/bootstrap.css';
import './assets/css/styles.css';
import './assets/css/customOwlCarousel.css';
// Product Finder
import './assets/css/customProductFinder.css';
// Product Page
import './assets/css/customProductOnly.css';
// Responsive Design
import './assets/css/responsive.css'; 
/*
  APP LAYOUT
  This is where the app's HTML structure and root placeholders should be defined.

  All routes share this root layout by default (this could be customized in RouteHandler),
  but components added to inner placeholders are route-specific.
*/


const Layout = ({ route }) => {

  let wrapperClass = "container";
  let paddingClass = "p-0";
  for(var component1 of route.placeholders["jss-main"]) {
    if (component1.placeholders !== undefined && component1.componentName === "Post-LayoutWrapper") {
      wrapperClass = "wide-wrap page-layout-right-sidebar page-layout-border";
    }
    if (component1.placeholders !== undefined && component1.componentName === "ProductFinder-LayoutWrapper") {
      wrapperClass = "wide-wrap page-layout-left-sidebar page-layout-border";
    }
    if (component1.placeholders !== undefined && component1.componentName === "ProductLayoutWrapper") {
      wrapperClass = "wide-wrap page-layout-right-sidebar page-layout-border";
      paddingClass = " ";
    }
  } 

  return(
    <React.Fragment>
      <Helmet>
        <title>
          {" "}
          {(route.fields &&
            route.fields.pageTitle &&
            route.fields.pageTitle.value) ||
            "Page"}{" "}
        </title>{" "}
        <meta name="keywords" content={route.fields && route.fields.metaKeywords && route.fields.metaKeywords.value}></meta>
        <meta name="description" content={route.fields && route.fields.metaDescription && route.fields.metaDescription.value}></meta>
      </Helmet>

      {/*
        VisitorIdentification is necessary for Sitecore Analytics to determine if the visitor is a robot.
        If Sitecore XP (with xConnect/xDB) is used, this is required or else analytics will not be collected for the JSS app.
        For XM (CMS-only) apps, this should be removed.

        VI detection only runs once for a given analytics ID, so this is not a recurring operation once cookies are established.
      */}
      <VisitorIdentification />
        <header className="main-hd">
            <Placeholder name="jss-header" rendering={route}/>
        </header>
          <main id="main_custom" className="wrapper">
            <Placeholder name="jss-main" rendering={route} />
          </main>
      <Placeholder name="jss-footer" rendering={route} />

    </React.Fragment>
  );
}

export default Layout;
