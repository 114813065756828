import React from 'react';
import { Text, withSitecoreContext } from '@sitecore-jss/sitecore-jss-react';
import { css } from '@emotion/react';
import { DotLoader } from 'react-spinners';
import {NavLink} from "react-router-dom";
import ReactComponent from "../../ReactComponent";
import { loader as gqlLoader } from "graphql.macro";
import { Query } from "react-apollo";
import './articleCatNav.css';

const ArticleCategoriesNavigationQuery = gqlLoader('./query.graphql');
const override = css`
  margin: auto;
`;

class ArticleCategoriesNavigation extends ReactComponent {

  constructor(props) {
    super(props);
    this.state = {
      isLoaded: false
    };
  }

  render() {
    return(
      <div className="cat__custom__article">
          <Query query={ArticleCategoriesNavigationQuery} variables={{indexname: this.indexName, rootpath: '/sitecore/content/' + this.appName + '/home'}}>
            {({ loading, error, data}) => {
              if (loading)
                return (
                  <div className='sweet-loading' style={{marginTop: '50px', marginBottom: '50px'}}>
                    <DotLoader
                      css={override}
                      sizeUnit={"px"}
                      size={50}
                      color={'#8986ca'}
                      loading={!this.state.isLoaded}
                    />
                  </div>
                );
              if (error)
                return (
                    <div>Error: {error.message}</div>
                );
              return (
                data && data.product.results.items.map(function (category, idx) {
                  let showBackgroundCover = {display: 'none'};
                  if (category.item !== null) {
                    showBackgroundCover = {backgroundImage: 'url(' + category.item.articleImage.src + ')'};
                  }
                  return (
                    <div key={idx} className={"col-md-3"}>
                      {category.item !== null &&
                        (
                          category.item.url.startsWith("http") === true ?
                            <a href={category.item && category.item.url} className="btn w-100"
                               style={showBackgroundCover}>
                              <Text tag="span" field={category.item && category.item.articleTitle}
                                    />
                            </a>
                            :
                            <NavLink to={category.item && category.item.url} title="" className="btn w-100"
                                     style={showBackgroundCover}>
                              <Text tag="span" field={category.item && category.item.articleTitle}
                                    />
                            </NavLink>
                        )
                      }
                    </div>
                  )
                })
              )
            }}
          </Query>
      </div>
    )
  }
}      

export default withSitecoreContext()(ArticleCategoriesNavigation);
