import React from 'react';
import { Text, Image, RichText } from '@sitecore-jss/sitecore-jss-react';
import {NavLink} from "react-router-dom";
import './featuredPosts.css';

const FeaturedPosts = (props) => {
    const {FeaturedPostsContentList} = props.fields;
    return (
        <>
            <div className="row_custom_small box-1 featured_posts_custom">
                <div className="col-md-12 mt-3">
                    <h1>
                        <div>
                            <Text field={props.fields.Title} tag="span"/>
                        </div>
                    </h1>
                    {
                        props.fields.Text !== '' &&
                            <blockquote>
                                <Text field={props.fields.Text} tag="p"/>
                            </blockquote>
                    }
                </div>
                {FeaturedPostsContentList &&
                FeaturedPostsContentList.map((listItem, index) => (
                    <div key={index} className="col-md-4">
                        <NavLink to={listItem.fields.PostLink.value.href}>
                            <img src={listItem.fields.Icon && listItem.fields.Icon.value.src} className="img_custom_full"/>
                            <div className="desc-cn">
                                <Text field={listItem.fields.title} tag="h3"/>
                                <RichText field={listItem.fields.content} tag="p"/>
                            </div>
                        </NavLink>
                    </div>

                ))}
            </div>
        </>
    );
};
export default FeaturedPosts;
